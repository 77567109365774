import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { eventShape } from 'app/shapes/events';
import {
  selectLocationEntities,
  selectPlaceEntities,
  selectImageEntities,
  selectEventAudienceEntities,
  selectEventTypesEntities,
  selectEventProgramsEntities,
  selectEventLanguagesEntities
} from 'app/selectors/EntitiesSelector';
import EventsSearchItem from './EventsSearchItem';

export class EventsSearchItemContainer extends React.PureComponent {
  render() {
    return <EventsSearchItem {...this.props} />;
  }
}

EventsSearchItemContainer.propTypes = {
  event: eventShape.isRequired,
  location: ImmutablePropTypes.map.isRequired,
  types: ImmutablePropTypes.list.isRequired,
  audiences: ImmutablePropTypes.list.isRequired,
  languages: ImmutablePropTypes.list.isRequired,
  program: ImmutablePropTypes.map,
  featuredImage: ImmutablePropTypes.map,
  isCalloutFeatured: PropTypes.bool
};

const mapStateToProps = (state, { event, isCalloutFeatured }) => {
  const eventTypes = selectEventTypesEntities(state);
  const eventPrograms = selectEventProgramsEntities(state);
  const eventAudiences = selectEventAudienceEntities(state);
  const eventLanguages = selectEventLanguagesEntities(state);
  const locations = selectLocationEntities(state);
  const places = selectPlaceEntities(state);
  const images = selectImageEntities(state);
  const definition = event.get('definition', Immutable.Map());

  return {
    types: definition.get('typeIds').map(id => eventTypes.get(id)),
    audiences: definition.get('audienceIds').map(id => eventAudiences.get(id)),
    languages: definition.get('languageIds').map(id => eventLanguages.get(id)),
    program: definition.get('programId') && eventPrograms.get(definition.get('programId')),
    location: definition.get('branchLocationId')
      ? locations.get(definition.get('branchLocationId'))
      : places.get(definition.get('nonBranchLocationId')),
    featuredImage: definition.get('featuredImageId') && images.get(definition.get('featuredImageId')),
    isCalloutFeatured: isCalloutFeatured || false
  };
};

export default connect(mapStateToProps)(EventsSearchItem);

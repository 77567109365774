import React from 'react';
import { eventShape } from 'app/shapes/events';
import EventsSearchItemContainer from '../EventsSearchItem/EventsSearchItemContainer';

import './CalloutFeaturedEvent.scss';

export default function CalloutFeaturedEvent({ event }) {
  return (
    <div className="cp-callout-featured-event">
      <EventsSearchItemContainer event={event} isCalloutFeatured />
    </div>
  );
}

CalloutFeaturedEvent.propTypes = {
  event: eventShape.isRequired
};
